import React from "react";
import "../../App.css";
import ContactMe from "../ContactMe";

export default function Contact() {
  return (
    <>
      <ContactMe />
    </>
  );
}
