import React from "react";
import "../../App.css";
import Work from "../Work";

export default function Experience() {
  return (
    <>
      <Work />
    </>
  );
}
