import React from "react";
import "../../App.css";
import Document from "../Document";

export default function Resume() {
  return (
    <>
      <Document />
    </>
  );
}
